export const GA_TRACKING_ID = process.env.GOOGLE_ANALYTICS_ID
const dev = process.env.NODE_ENV !== "production"

// IDが取得できない場合を想定する
export const existsGaId = GA_TRACKING_ID !== "" && !dev

// PVを測定する
export const pageview = (path) => {
  if (!existsGaId) {
    return
  }
  if (window && window.gtag) {
    window.gtag("config", GA_TRACKING_ID, {
      page_path: path,
    })
  }
}

// GAイベントを発火させる
export const event = ({
  action,
  category,
  label,
  value = "",
  interaction = true,
}) => {
  if (!existsGaId) {
    return
  }
  var e = {
    event_category: category,
    event_label: JSON.stringify(label),
    value,
  }
  if (interaction === false) {
    e.non_interaction = true
  }
  if (window && window.gtag) {
    window.gtag("event", action, e)
  }
}
