import React from "react"
import PropTypes from "prop-types"
import { useAmp } from "next/amp"
import AmpAnalytics from "components/Amp/AmpAnalytics"
import AmpPixel from "components/Amp/AmpPixcel"
import { GA_TRACKING_ID } from "lib/gtag"

const Layout = (props) => {
  const isAmp = useAmp()

  return (
    <>
      {props.children}
      {isAmp && (
        <>
          <AmpPixel
            type="pixel"
            script={{
              vars: {
                pixelId: process.env.FACEBOOK_PIXEL_ID,
              },
              triggers: {
                trackPageview: {
                  on: "visible",
                  request: "pageview",
                },
              },
            }}
          />
          <AmpAnalytics
            type="googleanalytics"
            script={{
              vars: {
                account: GA_TRACKING_ID,
                gtag_id: GA_TRACKING_ID,
                config: {
                  [GA_TRACKING_ID]: { groups: "default" },
                },
              },
            }}
          />
        </>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}

export default Layout
