import React from "react"
import { string } from "prop-types"
import AmpIncludeCustomElement from "./AmpIncludeCustomElement"
const AmpAnalytics = (props) => {
  return (
    <>
      <AmpIncludeCustomElement name="amp-analytics" version="0.1" />
      <amp-analytics type={props.type}>
        {props.script && (
          <script
            type="application/json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(props.script),
            }}
          />
        )}
      </amp-analytics>
    </>
  )
}

AmpAnalytics.propTypes = {
  type: string,
  script: string,
}

export default AmpAnalytics
