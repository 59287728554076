import React from "react"
import { string } from "prop-types"

const AmpPixel = (props) => {
  return (
    <>
      <amp-pixel
        src={`https://www.facebook.com/tr?id=${process.env.FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
        layout="nodisplay"></amp-pixel>
      <amp-analytics type="facebookpixel" id="facebook-pixel">
        <script
          type="application/json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(props.script),
          }}></script>
      </amp-analytics>
    </>
  )
}
AmpPixel.propTypes = {
  script: string,
}

export default AmpPixel
