import Head from "next/head"
import JavascriptTimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"
import Router from "next/router"
import * as gtag from "lib/gtag"
import Layout from "components/Layout"
import App from "next/app"
import TagManager from "react-gtm-module"
import "styles/main.css"

JavascriptTimeAgo.addLocale(en)

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }
    return { pageProps }
  }

  componentDidMount() {
    TagManager.initialize({ gtmId: "GTM-WHBGRLB" })

    if (!gtag.existsGaId) {
      return
    }
    const handleRouteChange = (path) => {
      gtag.pageview(path)
    }

    Router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange)
    }
  }

  render() {
    const { Component, pageProps } = this.props

    return (
      <Layout>
        <Head>
          <title>NobodySurf</title>
          <link rel="preconnect" href="https://stats.g.doubleclick.net" />
          <link rel="preconnect" href="https://www.google.com" />
          <link rel="preconnect" href="https://cdn.nobodysurf.com" />
          <link rel="preconnect" href="https://www.google.co.jp" />
          <link rel="preconnect" href="https://www.google-analytics.com" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://connect.facebook.net" />
          <link rel="preconnect" href="https://www.facebook.com" />
        </Head>
        <Component {...pageProps} />
      </Layout>
    )
  }
}

export default MyApp
